import React, { useState } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import Modal from 'react-bootstrap/Modal'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'
import CloseIcon from '../components/close'

const Gallery = ({ data }) => {
  const [show, setShow] = useState(false)
  const [image, setImage] = useState('')
  const [text, setText] = useState('')

  const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  return (
    <>
      <SEO title="Gallery" />
      <Nav>
        <div className="col text-left">
          <Link className="target fill-dark" to="/explore" style={{ height: "1.875rem" }}><ExploreIcon /></Link>
        </div>
      </Nav>
      <ReactFullpage
        licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
        sectionsColor={['#e8e7e3']}
        lazyLoading
        autoScrolling={false}
        scrollBar={false}

        render={() => (
          <ReactFullpage.Wrapper>
            <section className="section" key="section0" id="section0">
              <div className="container my-md-5 p-5 px-md-4">
                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Sky Lobby</h6>
                  {data.skylobby.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Sky Bar</h6>
                  {data.skybar.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Retail</h6>
                  {data.retail.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Gallery</h6>
                  {data.gallery.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Lobby</h6>
                  {data.lobby.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Porte Cochere</h6>
                  {data.portecochere.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>MRT Link</h6>
                  {data.mrt.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Podium</h6>
                  {data.podium.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Drop Off</h6>
                  {data.dropoff.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>Banking Hall</h6>
                  {data.bankinghall.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <h6 className="position-absolute overlay bg-dark mt-5 ml-3 p-2" style={{ width: "10rem" }}>TRX</h6>
                  {data.trx.edges.sort((a, b) => (a.name - b.name)).map(({ node }) => (
                    <div key={node.id} className={`col-md-4 p-3 ${node.relativeDirectory.slice(8)}`}>
                      <a href="./gallery#" onClick={() => {
                        setImage(node.childImageSharp.fluid)
                        setText(node.name)
                        setShow(true)
                      }}>
                        <Img
                          className="img-fluid"
                          fixed={node.childImageSharp.fixed}
                          objectFit="cover"
                          objectPosition="center center"
                          style={{ height: '200px' }}
                          alt={node.name}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </ReactFullpage.Wrapper>
        )}
      />

      <Modal
        animation={false}
        centered
        onHide={handleClose}
        show={show}
        size="lg"
      >
        <Modal.Body>
          <button
            type="button"
            className="close position-absolute"
            style={{width:"1.5rem", top:"-1.875rem", right:"0", fill:"#fff"}}
            onClick={handleClose}
          >
            <span aria-hidden="true"><CloseIcon /></span>
          </button>
          
          <Img
            className="w-100"
            fluid={image}
            alt={text}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Gallery

export const query = graphql`
  query {
    skylobby: allFile(filter: {relativeDirectory: {eq: "gallery/sky-lobby"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    skybar: allFile(filter: {relativeDirectory: {eq: "gallery/sky-bar"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    retail: allFile(filter: {relativeDirectory: {eq: "gallery/retail"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    gallery: allFile(filter: {relativeDirectory: {eq: "gallery/gallery"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    lobby: allFile(filter: {relativeDirectory: {eq: "gallery/lobby"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    portecochere: allFile(filter: {relativeDirectory: {eq: "gallery/porte-cochere"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    mrt: allFile(filter: {relativeDirectory: {eq: "gallery/mrt"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    podium: allFile(filter: {relativeDirectory: {eq: "gallery/podium"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    dropoff: allFile(filter: {relativeDirectory: {eq: "gallery/drop-off"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    bankinghall: allFile(filter: {relativeDirectory: {eq: "gallery/banking-hall"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
    trx: allFile(filter: {relativeDirectory: {eq: "gallery/trx"}, extension: {eq: "jpg"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(quality: 100, width: 600, height: 400, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          relativeDirectory
        }
      }
    }
  }
`